<template>
  <div class="row bottom-20">
    <div class="col-sm-4 ">
      <label :class="['semibold-weight', { 'no-margin': !editing }]">Location <span class="inline-help required">*</span></label>
    </div>

    <div class="col-sm-8">
      <div class="row">
        <div class="col-sm-4 top-10-xs">
          <typeahead-vertical
            :id="'opportunity-country'"
            :initial-value="opportunity.country"
            :options="countries"
            placeholder="Country"
            :hide-icon="true"
            :has-label="false"
            rules="required"
            :edit-mode="editing"
            :display-error="editing"
            v-model="opportunity.country"
            @input="countrySelected">
          </typeahead-vertical>
        </div>

        <div class="col-sm-4 top-10-xs">
          <typeahead-vertical
            :disabled="!isUsa(opportunity.country)"
            :id="'opportunity-state'"
            :initial-value="opportunity.state"
            :options="states"
            :placeholder="isUsa(opportunity.country) ? 'State' : 'N/A'"
            :hide-icon="true"
            :has-label="false"
            :edit-mode="editing"
            :display-error="editing"
            :force-select="false"
            v-model="opportunity.state">
          </typeahead-vertical>
        </div>

        <div class="col-sm-4 top-10-xs">
          <input
            v-show="editing"
            v-model="opportunity.city"
            type="text"
            name="opportunityCity"
            id="opportunity-city"
            class="form-control"
            placeholder="City">
          <div v-if="!editing">
            {{ opportunity.city }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import TypeaheadVertical from 'vue-app/shared/components/typeahead-vertical.vue';

import SharedLists from 'vue-app/shared/services/shared-lists-service.js';
export default {
  name: 'OpportunityLocation',

  components: {
    TypeaheadVertical
  },

  props: {
    opportunity: {
      type: Object,
      required: true
    },

    editing: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    countries() {
      return SharedLists.listOfCountries();
    },

    states() {
      return SharedLists.listOfStateObjects().map((stateObject) => stateObject.name);
    }
  },

  methods: {
    countrySelected() {
      if (this.opportunity.country !== 'United States') {
        this.opportunity.state = null;
      }
    },

    isUsa(attributeValue) {
      return attributeValue === 'United States';
    }
  }
};
</script>

